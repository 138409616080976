import styled from '@emotion/styled';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

const Formation = ({ data, location }) => {
  const formation = data.contentfulFormation;
  const url = formation.promoLink;

  useEffect(() => {
    window.location.replace(url)
  });

  return (
    <div></div> 
  );
};

Formation.propTypes = {
  data: PropTypes.object.isRequired,
};

export default Formation;

export const query = graphql`
  query($slug: String!) {
    contentfulFormation(slug: { eq: $slug } ) {
      promoLink
    }
  }
`;
